import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../../css/carousels.css";
import "../../css/opening.css";
import SlideTwo from "./SlideTwo.js";
import SlideOne from "./SlideOne.js";
import SlideThree from "./SlideThree.js";
import SlideFour from "./SlideFour.js";
import SlideFive from "./SlideFive.js";
import SlideSix from "./SlideSix.js";
// import Test2 from "../test2.js";

function Carousels() {
  const [count, setCount] = useState(0);

  const size = {
    width: "1000px",
    height: "auto",
  };

  const style = {
    backgroundColor: "#f4f4f4",
  };

  const center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  // const handleSlide = () => {
  //   count === 5 ? setCount(0) : setCount(count + 1);
  // };

  return (
    <div>
      <section
        className="library_section_desktop main_content_details"
        style={center}
      >
        <h2 className="floor_heading">{`Floor: ${count}`}</h2>
        <div className="crousel-image-col-desktop" style={center}>
          <Carousel
            style={style}
            cycle={false}
            interval={5000000000}
            className="carousel  slide carousel-fade"
            onSlide={(e)=>{setCount(e)}}
          >
            <Carousel.Item>
              <SlideOne size={size} />
            </Carousel.Item>
            <Carousel.Item>
              <SlideTwo size={size} />
            </Carousel.Item>
            <Carousel.Item>
              <SlideThree size={size} />
            </Carousel.Item>
            <Carousel.Item>
              <SlideFour size={size} />
            </Carousel.Item>
            <Carousel.Item>
              <SlideFive size={size} />
            </Carousel.Item>
            <Carousel.Item>
              <SlideSix size={size} />
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default Carousels;
